<template>
	<div :class="data.additionalClass">
		<transition name="fade2">
			<div class="banner" :style="{backgroundImage: 'url(' + background + ')'}" :class="data.bannerType">
				<div class="banner__abs_container">
					<video v-if="data.video" :src="data.video" autoplay loop  muted="" playsinline="" :poster="data.videoPoster" class="banner__video"></video>
				</div>
				<div class="container bannerContainer">
					<div class="banner__main">
						<h1 class="banner--title" v-html="data.title" :class="data.titleType" v-if="data.titleIsH1"></h1>
						<p class="banner--title" v-html="data.title" v-else></p>
						<p class="banner--desc" v-html="data.desc"></p>
					</div>
				</div>
			</div>
		</transition>
		<BreadCrumbs v-if="showBreadcrumbs" class="container"></BreadCrumbs>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/case/Banner'
</style>

<script>
	import BreadCrumbs from '@/components/app/BreadCrumbs'
	export default {
		props: ['data', 'showBreadcrumbs'],
		data: () => ({
			background: null
		}),
		components: {
			BreadCrumbs
		},
		methods: {
			setBanner: function() {
				if(this.data.mobileImage){
					if(window.innerWidth > 600){
						this.background = this.data.image;
					} else{
						this.background = this.data.mobileImage;
					}
				} else{
					this.background = this.data.image;
				}
			}
		},
		mounted(){
			var comp = this;
			comp.setBanner();
			window.addEventListener('resize', function(event){
				comp.setBanner();
			});
		}
	}
</script>
